import { useSelector } from 'react-redux'

import { Impersonator } from 'types'

import useAppDispatch from 'hooks/useAppDispatch'
import useCustomRedirect from 'hooks/useRedirect'

import { dismissError } from 'slices/auth'
import { logoutAction } from 'slices/auth'
import { getImpersonator, getImpersonatorErrorMsg, updateToken } from 'slices/impersonation'
import { getWhoami } from 'slices/whoami'

import { RootPaths } from 'utils/helpers'

import {
  StyledImpersonationBanner,
  StyledImpersonationBannerButton,
  StyledImpersonationError
} from './styles'


export function ImpersonatorBanner() {
  const whoami = useSelector(getWhoami)
  const dispatch = useAppDispatch()
  const impersonator = useSelector(getImpersonator) as Impersonator
  const error = useSelector(getImpersonatorErrorMsg)
  const redirectToLogin = useCustomRedirect(RootPaths.welcome)

  if (!impersonator || !whoami) return null

  const DEFAULT_ID = 'You'
  const impersonator_id = impersonator?.email || DEFAULT_ID
  const verbiage = impersonator_id == DEFAULT_ID ? 'are' : 'is'

  return (
    <>
      {error && (
        <StyledImpersonationError
          variant="filled"
          onClose={() => dispatch(dismissError())}
          severity="error"
          data-testid="ImpersonatorBannerError"
        >
          {error as string}
        </StyledImpersonationError>
      )}
      <StyledImpersonationBanner data-testid="ImpersonatorBanner">
        <strong>⚠ {`${impersonator_id}`}</strong> {verbiage} impersonating{' '}
        <strong>{`${whoami.display_name} ${whoami.user_profile_id}`}</strong>
        <div>
          <StyledImpersonationBannerButton
            color="primary"
            size="small"
            variant="contained"
            onClick={async () => {
              if (!impersonator?.impersonatee?.firebase) {
                await dispatch(logoutAction())
                redirectToLogin()
              } else {
                await dispatch(updateToken({
                  user_profile_id: null,
                }))

                // set the page to the current page minus query params
                // alternatively we could reload all components
                window.location.href = window.location.pathname
              }
            }}
          >
            Drop Impersonation
          </StyledImpersonationBannerButton>
        </div>
      </StyledImpersonationBanner>
    </>
  )
}

export default ImpersonatorBanner
