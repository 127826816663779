import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { postRecord } from 'api'

import { getAuthTokens } from 'slices/auth'
import { getImpersonator, getImpersonatorLoading } from 'slices/impersonation'

import { authenticatedThunkCreator } from 'slices/utils'
import useAppDispatch from 'hooks/useAppDispatch'

interface SessionArgs {
  access: string
  refresh: string
}

export const useSessionTrack = () => {
  const { access, refresh } = useSelector(getAuthTokens)
  const impersonator = useSelector(getImpersonator)
  const impersonationLoading = useSelector(getImpersonatorLoading)
  const dispatch = useAppDispatch()

  const hasAuthedArgs = !!access && !!refresh
  const isImpersonating = impersonator || impersonationLoading

  const sessionThunk = authenticatedThunkCreator<void, SessionArgs>(`sessionThunk`, async () => {
    try {
      await postRecord<void, void>({ access, refresh }, 'sessionTrack', 'v1')
    } catch {
      // do nothing
    }

  })

  useEffect(() => {
    if (!isImpersonating && hasAuthedArgs) {
      dispatch(sessionThunk({ access, refresh }))
    }
  }, [access, refresh, isImpersonating])
}
