import { useSelector } from 'react-redux'
import { Route, RouteProps } from 'react-router-dom'

import { UrlParams } from 'constants/UrlParams'

import useHTTPS from 'hooks/useHTTPS'
import { useQueryParams } from 'hooks/useQueryParams'
import useCustomRedirect from 'hooks/useRedirect'

import { getIsAuthenticated } from 'slices/auth'

import { RootPaths } from 'utils/helpers'


export default function PublicRoute({ path, ...props }: RouteProps) {
  useHTTPS()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const redirectToHome = useCustomRedirect(RootPaths.home)
  const query = useQueryParams()

  if (path === RootPaths.welcome && isAuthenticated) {
    const userProfileId = query.get(UrlParams.userProfileId)
    return redirectToHome({
      returnAsComponent: true,
      search: userProfileId ? `user_profile_id=${userProfileId}` : undefined
    })!
  }

  return <Route {...props} />
}
