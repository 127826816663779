import {
  DailyQuestion,
  OTPVerifyResponse,
  AuthTokensDTO,
  ImpersonationArgs,
  AuthedFetchDTO,
  AuthedFetchOneDTO,
  AuthedUpsertDTO
} from 'types'

import { LoginType, OTPChannel } from 'pages/login/constants'

import { APIVersion, conditionalObject } from 'utils/helpers'

import ENDPOINTS from './endpoints'
import {
  HttpError,
  _get,
  _post,
  _post_auth,
  _put,
  _patch,
  _public_post,
  _rms_put
} from './protocols'

export const fetchRecords = async <RecordT, ParamsT>(
  authedDTO: AuthedFetchDTO<ParamsT>,
  endpointKey: keyof typeof ENDPOINTS,
  apiVersion: keyof typeof APIVersion = APIVersion.v1
) => {
  const urlPath = ENDPOINTS[endpointKey]
  const result: RecordT = await _get(urlPath, authedDTO, apiVersion)
  return result
}

export const fetchRecord = async <RecordT>(
  authedDTO: AuthedFetchOneDTO,
  endpointKey: keyof typeof ENDPOINTS,
  apiVersion: keyof typeof APIVersion = APIVersion.v1
) => {
  const { pk } = authedDTO
  const urlPath = `${ENDPOINTS[endpointKey]}/${pk}`
  const result: RecordT = await _get(urlPath, authedDTO, apiVersion)
  return result
}

export const postRecord = async <RecordT, DataT>(
  authedDTO: AuthedUpsertDTO<DataT>,
  endpointKey: keyof typeof ENDPOINTS,
  apiVersion: keyof typeof APIVersion = APIVersion.v1
) => {
  const urlPath = ENDPOINTS[endpointKey]
  const result: RecordT = await _post(urlPath, authedDTO, apiVersion)
  return result
}

export const putRecord = async <RecordT, DataT>(
  authedDTO: AuthedUpsertDTO<DataT>,
  endpointKey: keyof typeof ENDPOINTS,
  apiVersion: keyof typeof APIVersion = APIVersion.v1
) => {
  const { id } = authedDTO
  const urlPath = ENDPOINTS[endpointKey]
  const result: RecordT = await _put(`${urlPath}/${id}`, authedDTO, apiVersion)
  return result
}

export const patchRecord = async <RecordT, DataT>(
  authedDTO: AuthedUpsertDTO<DataT>,
  endpointKey: keyof typeof ENDPOINTS,
  apiVersion: keyof typeof APIVersion = APIVersion.v1
) => {
  const { id } = authedDTO
  const urlPath = ENDPOINTS[endpointKey]
  const result: RecordT = await _patch(`${urlPath}/${id}`, authedDTO, apiVersion)
  return result
}

export const fetchAuthToken = async (loginToken: string) => {
  const tokens: AuthTokensDTO = await _post_auth(ENDPOINTS.obtainToken, {
    login_token: loginToken
  })
  return tokens
}

export const fetchImpersonatedAuth = async (impersonationArgs: ImpersonationArgs) => {
  const tokens: AuthTokensDTO = await _post_auth(ENDPOINTS.impersonate, impersonationArgs)
  return tokens
}

export const refreshToken = async (refreshToken: string) => {
  const tokens: AuthTokensDTO = await _post_auth(ENDPOINTS.refreshToken, {
    refresh: refreshToken
  })
  return tokens
}

export const fetchOnarollDaily = async (authedDTO: AuthedFetchDTO) => {
  try {
    const dailyQuestion: DailyQuestion = await _get(
      ENDPOINTS.onarollDaily,
      authedDTO,
      APIVersion.v1
    )
    return dailyQuestion.question_id !== null ? dailyQuestion : ({} as DailyQuestion)
  } catch (e) {
    if ((e as HttpError).status === 404) {
      // This is fine: nothing configured for today
      return {} as DailyQuestion
    }
    throw e
  }
}

export const putOnboardingFinished = async (tokens: AuthTokensDTO) => {
  const urlPath = ENDPOINTS.onboardingSeen
  await _put(`${urlPath}`, { ...tokens, data: {} }, APIVersion.v1)
}

// Auth
export const sendOTPV2 = async ({
  to,
  channel = OTPChannel.phone
}: {
  to: string
  channel?: OTPChannel
}) => {
  if (!to) {
    throw new Error(
      `You must pass in a valid ${
        channel === OTPChannel.email ? 'email' : 'phone number'
      }`
    )
  }
  const body = { channel, to }
  return await _public_post(body, ENDPOINTS.sendOTP, APIVersion.v2)
}

export const verifyOTP = async (
  value: string,
  code: string,
  loginType: keyof typeof LoginType
) => {
  const body = {
    code,
    ...conditionalObject({ email: value }, loginType === LoginType.email),
    ...conditionalObject({ phone_number: value }, loginType === LoginType.phone)
  }
  const res = await _public_post(body, ENDPOINTS.verifyOTP)
  if (!res.ok) {
    const body = await res.json()
    throw new Error(body)
  }
  return (await res.json()) as OTPVerifyResponse
}

export const enrichToken = async ({
  idToken,
  impersonateUserProfileId
}: {
  idToken: string
  impersonateUserProfileId?: string
}) => {
  let body = null
  if (impersonateUserProfileId) {
    body = {
      impersonate: impersonateUserProfileId
    }
  }
  const res = await _rms_put('auth/token', idToken, body)
  if (!res.ok) {
    const errorData = await res.json()
    const error = new Error(errorData.message || 'Authentication failed')
    ;(error as any).status = res.status
    throw error
  }
  return res
}
