import {
  COMPARISON,
  WORK_A_SHIFT,
  WORK_A_TIME_BASED_SHIFT
} from '../../constants/globals'
import { CompletionState } from '../../generated/graphql'

const DEFAULT = 'default'

export const GOAL_RESULT_TEXT = {
  [WORK_A_SHIFT]: {
    [CompletionState.Complete]: 'You hit your goal!',
    [CompletionState.Incomplete]: 'Our data shows you didn’t work this day.',
    [CompletionState.AwaitingData]: 'Your goal data is still processing, Check back soon!'
  },
  [WORK_A_TIME_BASED_SHIFT]: {
    [CompletionState.Complete]: 'You hit your goal!',
    [CompletionState.Incomplete]: 'Our data shows you didn’t work this day.',
    [CompletionState.AwaitingData]: 'Your goal data is still processing, Check back soon!'
  },
  [COMPARISON]: {
    [CompletionState.Complete]: 'You hit the goal',
    [CompletionState.Incomplete]: 'You didn’t hit your goal',
    [CompletionState.AwaitingData]:
      'Your goal data is still processing, Check back soon!',
    [CompletionState.LocationCompleteMissingShift]: `Your team hit this goal, but our data shows you didn't work this day`,
    [CompletionState.LocationIncompleteMissingShift]: `Your team didn't hit this goal`
  },
  [DEFAULT]: {
    [CompletionState.Complete]: 'You hit your goal!',
    [CompletionState.Incomplete]: 'Our data shows you didn’t work this day.',
    [CompletionState.AwaitingData]: 'Your goal data is still processing, Check back soon!'
  }
}

export const GOAL_RESULT_STATUS = {
  [WORK_A_SHIFT]: {
    [CompletionState.Complete]: 'achieved',
    [CompletionState.Incomplete]: 'not_achieved',
    [CompletionState.AwaitingData]: 'processing'
  },
  [WORK_A_TIME_BASED_SHIFT]: {
    [CompletionState.Complete]: 'achieved',
    [CompletionState.Incomplete]: 'not_achieved',
    [CompletionState.AwaitingData]: 'processing'
  },
  [COMPARISON]: {
    [CompletionState.Complete]: 'achieved',
    [CompletionState.Incomplete]: 'not_achieved',
    [CompletionState.AwaitingData]: 'processing',
    [CompletionState.LocationCompleteMissingShift]: 'store_achieved',
    [CompletionState.LocationIncompleteMissingShift]: 'not_achieved'
  },
  [DEFAULT]: {
    [CompletionState.Complete]: 'achieved',
    [CompletionState.Incomplete]: 'not_achieved',
    [CompletionState.AwaitingData]: 'processing'
  }
}

export const getGoalResultText = (
  goalType: string,
  state: string,
  value?: string,
  units?: string
): string => {
  if (goalType === COMPARISON && state !== CompletionState.AwaitingData) {
    return `${GOAL_RESULT_TEXT[goalType][state]}${value && units ? `: ${value} ${units}` : '.'}`
  }
  if (GOAL_RESULT_TEXT[goalType]) {
    return GOAL_RESULT_TEXT[goalType][state]
  }
  return GOAL_RESULT_TEXT[DEFAULT][state]
}

export const getGoalResultStatus = (goalType: string, state: string): string => {
  if (GOAL_RESULT_TEXT[goalType]) {
    return GOAL_RESULT_STATUS[goalType][state]
  }
  return GOAL_RESULT_STATUS[DEFAULT][state]
}
