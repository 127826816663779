import { logAndReportCrash, setLanguageHeader } from 'redux/middleware'
import { migrations } from 'redux/migrations'

import { AsyncThunk, combineReducers, configureStore } from '@reduxjs/toolkit'

import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  PersistConfig,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  ADMIN_CELEBRATION_POINTS,
  ADMIN_CSV_SCHEMAS,
  ADMIN_EMPLOYMENTS,
  ADMIN_GOALS,
  ADMIN_GOAL_FREQUENCIES,
  ADMIN_GOAL_POINT_GROUPS,
  ADMIN_STORES,
  ADMIN_TEAM_MEMBERS,
  AUTH,
  BRANDS,
  GOALS,
  JACKPOT,
  LOCATIONS,
  ONBOARDING_PERKS,
  PERKS,
  POINTS,
  REDEEMED_PERKS,
  SCHEDULES,
  SNACKBAR,
  STORES,
  STORE_GROUPS,
  STORE_SWITCHER,
  TEAM_ROLES,
  WHOAMI,
  DAILY_QUESTION,
  FAVORITE_PERKS,
  IMPERSONATOR
} from 'slices/constants'

import {
  adminCelebrationPointsReducer,
  adminCSVSchemasReducer,
  adminEmploymentReducer,
  adminGoalFrequenciesReducer,
  adminGoalPointGroupsReducer,
  adminGoalsReducer,
  adminStoresReducer,
  adminTeamMembersReducer,
  locationsReducer,
  schedulesReducer,
  storeGroupsReducer,
  teamRolesReducer
} from 'slices/admin'
import { authReducer } from 'slices/auth'
import { brandsReducer } from 'slices/brands'
import { dailyQuestionReducer } from 'slices/dailyQuestion'
import { goalsReducer } from 'slices/goals'
import { impersonatorReducer } from 'slices/impersonation'
import { jackpotReducer } from 'slices/jackpot'
import {
  onboardingPerksReducer,
  perksReducer,
  redeemedPerksReducer,
  favoritePerksReducer
} from 'slices/perks'
import { pointsReducer } from 'slices/points'
import { snackbarReducer } from 'slices/snackbar'
import { storeSwitcherReducer } from 'slices/storeSwitcher'
import { storesReducer } from 'slices/stores'
import { whoamiReducer } from 'slices/whoami'


const brandPersistConfig: PersistConfig<ReturnType<typeof brandsReducer>> = {
  key: BRANDS,
  storage,
  blacklist: ['hasLoaded', 'isLoading']
}

const appReducer = combineReducers({
  [AUTH]: authReducer,
  [IMPERSONATOR]: impersonatorReducer,
  [ADMIN_GOALS]: adminGoalsReducer,
  [ADMIN_TEAM_MEMBERS]: adminTeamMembersReducer,
  [ADMIN_GOAL_POINT_GROUPS]: adminGoalPointGroupsReducer,
  [ADMIN_GOAL_FREQUENCIES]: adminGoalFrequenciesReducer,
  [ADMIN_CSV_SCHEMAS]: adminCSVSchemasReducer,
  [ADMIN_CELEBRATION_POINTS]: adminCelebrationPointsReducer,
  [ADMIN_STORES]: adminStoresReducer,
  [ADMIN_EMPLOYMENTS]: adminEmploymentReducer,
  [DAILY_QUESTION]: dailyQuestionReducer,
  [SNACKBAR]: snackbarReducer,
  [BRANDS]: persistReducer(brandPersistConfig, brandsReducer),
  [GOALS]: goalsReducer,
  [JACKPOT]: jackpotReducer,
  [PERKS]: perksReducer,
  [ONBOARDING_PERKS]: onboardingPerksReducer,
  [REDEEMED_PERKS]: redeemedPerksReducer,
  [FAVORITE_PERKS]: favoritePerksReducer,
  [POINTS]: pointsReducer,
  [LOCATIONS]: locationsReducer,
  [TEAM_ROLES]: teamRolesReducer,
  [SCHEDULES]: schedulesReducer,
  [STORE_GROUPS]: storeGroupsReducer,
  [STORES]: storesReducer,
  [STORE_SWITCHER]: storeSwitcherReducer,
  [WHOAMI]: whoamiReducer
})

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === 'LOGOUT/fulfilled') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

/**
 * Any time the redux store drastically changes, we should:
 * 1. add a migration (see above)
 * 2. increase the version number
 */
const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [AUTH, STORE_SWITCHER, IMPERSONATOR],
  migrate: createMigrate(migrations, { debug: false })
}

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export const reduxStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        // Whoami is not serializable. It is a full blown class returned - so simply ignore it
        // TODO: Should look into serializing it when adding to the store
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
      .concat(logAndReportCrash)
      .concat(setLanguageHeader)
})

export const persistedStore = persistStore(reduxStore)

export type OnarollThunkDispatch = typeof reduxStore.dispatch

export type StoreUpdate<T = void> = (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => Promise<T | number | string | undefined | void> | void

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericAsyncThunk = AsyncThunk<any, any, any>
