import { Colors } from 'theme'

import { ReactElement } from 'react'

import { ReactComponent as CarGoalIcon } from 'assets/icons/GoalIcons/CarGoalIcon.svg'
import { ReactComponent as CellPhoneGoalIcon } from 'assets/icons/GoalIcons/CellPhoneGoalIcon.svg'
import { ReactComponent as CharityGoalIcon } from 'assets/icons/GoalIcons/CharityGoalIcon.svg'
import { ReactComponent as ClockGoalIcon } from 'assets/icons/GoalIcons/ClockGoalIcon.svg'
import { ReactComponent as ClothesGoalIcon } from 'assets/icons/GoalIcons/ClothesGoalIcon.svg'
import { ReactComponent as CoffeeGoalIcon } from 'assets/icons/GoalIcons/CoffeeGoalIcon.svg'
import { ReactComponent as ComparisonGoal } from 'assets/icons/GoalIcons/ComparisonGoal.svg'
import { ReactComponent as DessertGoalIcon } from 'assets/icons/GoalIcons/DessertGoalIcon.svg'
import { ReactComponent as DrinkWithStrawGoalIcon } from 'assets/icons/GoalIcons/DrinkWithStrawGoalIcon.svg'
import { ReactComponent as ExtraSideGoalIcon } from 'assets/icons/GoalIcons/ExtraSideGoalIcon.svg'
import { ReactComponent as FastFoodTrayGoalIcon } from 'assets/icons/GoalIcons/FastFoodTrayGoalIcon.svg'
import { ReactComponent as FoodGoalIcon } from 'assets/icons/GoalIcons/FoodGoalIcon.svg'
import { ReactComponent as FrenchFriesGoalIcon } from 'assets/icons/GoalIcons/FrenchFriesGoalIcon.svg'
import { ReactComponent as GenericGoal } from 'assets/icons/GoalIcons/GenericGoal.svg'
import { ReactComponent as GiftCardGoalIcon } from 'assets/icons/GoalIcons/GiftCardGoalIcon.svg'
import { ReactComponent as HamburgerGoalIcon } from 'assets/icons/GoalIcons/HamburgerGoalIcon.svg'
import { ReactComponent as NoodleGoalIcon } from 'assets/icons/GoalIcons/NoodleGoalIcon.svg'
import { ReactComponent as QuestionMarkGoalIcon } from 'assets/icons/GoalIcons/QuestionMarkGoalIcon.svg'
import { ReactComponent as ReviewGoalIcon } from 'assets/icons/GoalIcons/ReviewGoalIcon.svg'
import { ReactComponent as SaladGoalIcon } from 'assets/icons/GoalIcons/SaladGoalIcon.svg'
import { ReactComponent as SalesGoalIcon } from 'assets/icons/GoalIcons/SalesGoalIcon.svg'
import { ReactComponent as SandwichGoalIcon } from 'assets/icons/GoalIcons/SandwichGoalIcon.svg'
import { ReactComponent as ScheduleGoalIcon } from 'assets/icons/GoalIcons/ScheduleGoalIcon.svg'
import { ReactComponent as SoapGoalIcon } from 'assets/icons/GoalIcons/SoapGoalIcon.svg'
import { ReactComponent as SoupGoalIcon } from 'assets/icons/GoalIcons/SoupGoalIcon.svg'
import { ReactComponent as StarGoalIcon } from 'assets/icons/GoalIcons/StarGoalIcon.svg'
import { ReactComponent as SupportGoalIcon } from 'assets/icons/GoalIcons/SupportGoalIcon.svg'
import { ReactComponent as SurpriseGoalIcon } from 'assets/icons/GoalIcons/SurpriseGoalIcon.svg'
import { ReactComponent as TacoGoalIcon } from 'assets/icons/GoalIcons/TacoGoalIcon.svg'
import { ReactComponent as TriviaGoalIcon } from 'assets/icons/GoalIcons/TriviaGoalIcon.svg'
import { ReactComponent as WorkAShiftGoal } from 'assets/icons/GoalIcons/WorkAShiftGoal.svg'
import { ReactComponent as WorkATimeBasedShiftGoal } from 'assets/icons/GoalIcons/WorkATimeBasedShiftGoal.svg'
import { ReactComponent as WorkerGoalIcon } from 'assets/icons/GoalIcons/WorkerGoalIcon.svg'

import GoalIconWrapper from './GoalIconWrapper'

export enum GoalIconTypes {
  car = 'car',
  cellPhone = 'cellPhone',
  charity = 'charity',
  clock = 'clock',
  clothes = 'clothes',
  coffee = 'coffee',
  dessert = 'dessert',
  drinkWithStraw = 'drinkWithStraw',
  extraSide = 'extraSide',
  fastFoodTray = 'fastFoodTray',
  food = 'food',
  frenchFries = 'frenchFries',
  giftCard = 'giftCard',
  hamburger = 'hamburger',
  noodle = 'noodle',
  onaroll = 'onaroll',
  questionMark = 'questionMark',
  review = 'review',
  salad = 'salad',
  sales = 'sales',
  sandwich = 'sandwich',
  schedule = 'schedule',
  soap = 'soap',
  soup = 'soup',
  support = 'support',
  surprise = 'surprise',
  taco = 'taco',
  trivia = 'trivia',
  worker = 'worker',
  generic = 'generic',
  comparisonGoal = 'comparison',
  workAShiftGoal = 'work_a_shift',
  workATimeBasedShiftGoal = 'work_a_time_based_shift'
}

const GoalIconsMap: { [key: string]: [ReactElement, 'stroke' | 'fill'] } = {
  [GoalIconTypes.car]: [<CarGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.cellPhone]: [<CellPhoneGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.charity]: [<CharityGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.clock]: [<ClockGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.clothes]: [<ClothesGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.coffee]: [<CoffeeGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.dessert]: [<DessertGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.drinkWithStraw]: [<DrinkWithStrawGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.extraSide]: [<ExtraSideGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.fastFoodTray]: [<FastFoodTrayGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.food]: [<FoodGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.frenchFries]: [<FrenchFriesGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.giftCard]: [<GiftCardGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.hamburger]: [<HamburgerGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.noodle]: [<NoodleGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.onaroll]: [<StarGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.questionMark]: [<QuestionMarkGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.review]: [<ReviewGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.salad]: [<SaladGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.sales]: [<SalesGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.sandwich]: [<SandwichGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.schedule]: [<ScheduleGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.soap]: [<SoapGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.soup]: [<SoupGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.support]: [<SupportGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.surprise]: [<SurpriseGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.taco]: [<TacoGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.trivia]: [<TriviaGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.worker]: [<WorkerGoalIcon key="1" />, 'fill'],
  [GoalIconTypes.generic]: [<GenericGoal key="1" />, 'fill'],
  [GoalIconTypes.comparisonGoal]: [<ComparisonGoal key="1" />, 'fill'],
  [GoalIconTypes.workAShiftGoal]: [<WorkAShiftGoal key="1" />, 'fill'],
  [GoalIconTypes.workATimeBasedShiftGoal]: [<WorkATimeBasedShiftGoal key="1" />, 'fill']
}

export interface GoalIconProps {
  color?: string
  size?: string
  type: GoalIconTypes
}

const getIcon = (type: GoalIconTypes) => {
  return GoalIconsMap[type] || [<GenericGoal key="1" />, 'fill']
}

export const GoalIcon = ({ type, color = Colors.purple, size = '48' }: GoalIconProps) => {
  const [icon, strokeOrFill] = getIcon(type)
  return (
    <GoalIconWrapper color={color} size={size} strokeOrFill={strokeOrFill}>
      {icon}
    </GoalIconWrapper>
  )
}

export default GoalIcon
