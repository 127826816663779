import { useFirebase } from '../pages/login/form/otp/useFirebase'

import useAppDispatch from './useAppDispatch'

import { fetchAuthTokenThunk, logoutAction } from 'slices/auth'

import { RootPaths } from 'utils/helpers'
import { logError, logInfo } from 'utils/logger'
import { setDDUserSessionId } from 'utils/session'

import useCustomRedirect from './useRedirect'

export default async function useAuthenticateUser(
  isAuthenticated: boolean,
  loginToken: string,
  storedLoginToken: string,
  userProfileId?: string
) {
  const dispatch = useAppDispatch()
  const redirectToHome = useCustomRedirect(RootPaths.home)
  const redirectToLogin = useCustomRedirect(RootPaths.welcome)
  const { authenticateFirebaseUser } = useFirebase()
  const authenticateWithFirebase =
    localStorage.getItem('loginToken') === 'auth' || loginToken === 'auth'
  const authenticateUser = async () => {
    try {
      // see https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
      await dispatch(fetchAuthTokenThunk(loginToken)).unwrap()
      logInfo(`User has successfully authenticated! Attemping to redirect to home page`)
      setDDUserSessionId(true)
      redirectToHome()
    } catch (error) {
      logError(
        `Unable to authenticate user! Redirecting to login... ${
          (error as Error)?.message
        }`,
        {
          error
        }
      )
      await dispatch(logoutAction())
      redirectToLogin()
    }
  }

  if (isAuthenticated && loginToken === storedLoginToken) {
    logInfo('User is already authenticated! Skipping auth flow...', {
      isAuthenticated,
      loginToken
    })
    setDDUserSessionId()
    redirectToHome()
    return
  }

  if (authenticateWithFirebase) {
    await authenticateFirebaseUser(redirectToHome, userProfileId)
  } else if (loginToken) {
    await authenticateUser()
  }
}
