import { FONT_FAMILY } from 'theme'

import { styled } from '@mui/material'

import { AnimatedContent } from 'components/AnimatedContent'

export const StyledPage = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  background: theme.palette.colors.black,
  justifyContent: 'space-between',
  overflow: 'hidden',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(7),
  paddingRight: theme.spacing(7),
  fontFamily: FONT_FAMILY
}))

export const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  '#BackButton svg > path': {
    fill: theme.palette.common.white
  },
  '& > svg': {
    left: 'calc(50% - 20px)', // offsset by back button which is 20px
    position: 'relative',
    transform: 'translateX(-50%)'
  }
}))

export const StyledFooter = styled(AnimatedContent)(({ theme }) => ({
  // Push content above the fold (due to browser UI like navs etc covering content)
  marginBottom: theme.spacing(10)
}))
