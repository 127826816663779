import { Colors, getSpacing } from 'theme'

import { Alert } from '@mui/material'
import Box from '@mui/material/Box'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { BrandedLogo } from '../../components'

import { StyledPage } from '../LoginFormLayout/LoginFormLayout.styles'

import { useFirebase } from '../otp/useFirebase'

export default function MagicLink() {
  const { t } = useTranslation()
  const { processMagicLinkReceived, firebaseLoginError } = useFirebase()

  useEffect(() => {
    processMagicLinkReceived()
  }, [])

  return (
    <StyledPage data-testid="MagicLinkPage">
      {firebaseLoginError && (
        <Alert severity="error" variant="filled" style={{ borderRadius: '0' }}>
          {firebaseLoginError}
        </Alert>
      )}
      <Box
        m="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ gap: getSpacing(4) }}
      >
        <BrandedLogo />
        <Box display="block" sx={{ color: Colors.white, fontSize: getSpacing(5) }}>
          {t('pages.login.form.magicLink.loading')}
        </Box>
      </Box>
    </StyledPage>
  )
}
