import { getAuth, signOut } from 'firebase/auth'

import storage from 'redux-persist/lib/storage'

import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { fetchAuthToken, refreshToken } from 'api'

import { AuthTokensDTO, LoginToken } from 'types'

import { AUTH } from 'slices/constants'

import { RootState } from '../../reduxStore'
import { RootPaths } from 'utils/helpers'

import { clearDDUserSessionId } from 'utils/session'

export const logoutAction = createAsyncThunk(
  `LOGOUT`,
  async () => {
    clearDDUserSessionId()
    const firebaseInUse = localStorage.getItem('prefix') === 'Bearer'
    localStorage.clear()
    await storage.removeItem('persist:root')
    if (firebaseInUse) {
      const auth = getAuth()
      await signOut(auth)
    }
    window.location.assign(RootPaths.welcome)
    return {
      type: 'LOGOUT'
    }
  }
)

export const fetchAuthTokenThunk = createAsyncThunk(
  `${AUTH}/fetchAuthToken`,
  async (loginToken: LoginToken) => {
    const tokens: AuthTokensDTO = await fetchAuthToken(loginToken)
    return tokens
  }
)

export const refreshTokenThunk = createAsyncThunk(
  `${AUTH}/refreshAuthToken`,
  async (_, { getState }) => {
    const state = getState() as RootState
    const token = state.auth.refreshToken
    const tokens: AuthTokensDTO = await refreshToken(token as string)
    return tokens
  }
)

interface FirebaseTokens {
  access: string
  refresh: string
}

const firebaseTokens = (action: PayloadAction<FirebaseTokens>) => {
  return action.payload
}

export const firebaseTokensThunk = createAsyncThunk(
  'firebaseTokensThunk',
  ({ idToken, refreshToken }: { idToken: string; refreshToken: string }) => {
    return firebaseTokens({
      payload: {
        access: idToken,
        refresh: refreshToken
      },
      type: 'firebaseTokensThunk'
    })
  }
)

export const refreshFirebaseTokensThunk = createAsyncThunk(
  'refreshFirebaseTokensThunk',
  async (_, { getState }) => {
    const auth = getAuth()
    // first check the impersonation use case where refreshToken is ''
    const state = getState() as RootState
    if (state.auth.refreshToken.length < 1) {
      return {
        refresh: '',
        access: state.auth.accessToken
      }
    }

    const res = await auth.currentUser?.getIdToken(true)
    return {
      refresh: auth.currentUser?.refreshToken || '',
      access: res || ''
    }
  }
)
