import { isEmpty } from 'lodash'

import {
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  createSelector,
  EntityAdapter,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit'

import type { AnyAction } from 'redux'

import { HttpError } from 'api/protocols'

import {
  ByStoreFilters,
  PaginatedResponse,
  AuthTokensDTO,
  FetchCachedThunkProps,
  FetchWithParamsAndLazyLoadProps,
  FetchWithParamsThunk,
  GenericDTO,
  Impersonator,
  LazyLoadThunkProps
} from 'types'

import {
  logoutAction,
  refreshFirebaseTokensThunk,
  refreshTokenThunk
} from './auth/actions'

import { clearDDUserSessionId } from 'utils/session'

import { RootState } from 'reduxStore'

import { RequestState } from './constants'

/* THUNK HELPERS */
const getAuthedArgs = <ArgsT>(
  tokens: AuthTokensDTO,
  args?: ArgsT
): ArgsT & AuthTokensDTO => {
  if (!args) return tokens as ArgsT & AuthTokensDTO
  return { ...args, ...tokens }
}

export const authenticatedThunkCreator = <ResponseDTO, ArgsT = undefined>(
  actionType: string,
  thunk: AsyncThunkPayloadCreator<ResponseDTO, ArgsT & AuthTokensDTO>,
  options?: AsyncThunkOptions<ArgsT>
) =>
  createAsyncThunk(
    actionType,
    async (args, thunkAPI) => {
      const state = thunkAPI.getState() as RootState
      let authedArgs = getAuthedArgs(
        {
          access: state.auth.accessToken,
          refresh: state.auth.refreshToken
        },
        args,
      )

      try {
        return await thunk(authedArgs, thunkAPI)
      } catch (error) {
        const httpError = error as HttpError
        if (httpError.status === 401) {
          try {
            const refreshTokens =
              localStorage?.getItem('prefix')?.toUpperCase() === 'BEARER'
                ? refreshFirebaseTokensThunk
                : refreshTokenThunk
            const res = await thunkAPI.dispatch(refreshTokens()).unwrap()
            if (res === undefined) {
              // Avoid re-trying thunk if we fail to refresh tokens
              throw new Error(`Failed refreshing tokens - ${httpError.message}`)
            }
            // Retry after refreshing tokens
            authedArgs = getAuthedArgs({
              access: res.access,
              refresh: res.refresh,
            }, args)
            return await thunk(authedArgs, thunkAPI)
          } catch (err) {
            clearDDUserSessionId()
            await thunkAPI.dispatch(logoutAction())
            return thunkAPI.rejectWithValue((err as HttpError).message)
          }
        }
        return thunkAPI.rejectWithValue(httpError.message)
      }
    },
    options
  )

/* REDUCER REUSABLES */
/* There may be a use case for separating out the request state handlers
based on a bulk request vs. a single record request, but leaving for now */
export const handleRequestFulfilled = (state: RequestState) => {
  state.isLoading = false
  state.hasLoaded = true
  state.error = null
}

export const handleRequestRejected = <P>(
  state: RequestState,
  action: PayloadAction<P>
) => {
  state.isLoading = false
  state.error = action.payload
}

export const handleUpdateManyState = <T>(
  entityAdapter: EntityAdapter<T>,
  state: EntityState<T> & RequestState,
  action: AnyAction
) => {
  const { results = [] } = action.payload
  const { arg } = action.meta
  const isFresh = Boolean(arg?.isInitial)
  // If we have a search query then we are filtering which means we want to override our state with the new results
  // If we are fetching for the first time (isFresh), we should also set the state to the returned response results
  if (isFresh) {
    entityAdapter.setAll(state, results)
  } else {
    entityAdapter.setMany(state, results)
  }
  handleRequestFulfilled(state)
}

/* API REQUEST FORMATTING */
export const cleanParams = (params?: GenericDTO) => {
  if (!params) return
  for (const paramName in params) {
    if (!params[paramName as keyof GenericDTO]) {
      delete params[paramName as keyof GenericDTO]
    }
  }
  return params
}

export const formatParamsRequest = (
  authedDTO: AuthTokensDTO & FetchWithParamsThunk<GenericDTO>
) => {
  const queryParams = cleanParams(authedDTO?.queryParams)
  return { ...authedDTO, queryParams }
}

export const getPageParams = <T>(
  prevResults: PaginatedResponse<T> | null,
  isFresh?: boolean
) =>
  (!isFresh &&
    prevResults?.next &&
    new URL(prevResults.next).searchParams.get('cursor')) ||
  ''

export const returnCachedData = <T>(
  prevResults: PaginatedResponse<T> | null,
  options?: FetchCachedThunkProps,
  params?: GenericDTO
) => prevResults && options?.getCachedResults && (!params || isEmpty(params))

export const getIsFreshRequest = (options: LazyLoadThunkProps = {}) => {
  return options?.isInitial
}

export const getTransformedParams = <T>(
  prevResults: PaginatedResponse<T> | null,
  options: FetchWithParamsAndLazyLoadProps<ByStoreFilters> = {}
) => {
  const isFresh = getIsFreshRequest(options)
  const nextPageCursor = getPageParams<T>(prevResults, isFresh)
  const params = cleanParams({ ...options?.queryParams, cursor: nextPageCursor })
  return params
}

export const selectError = createSelector(
  (state: RootState) => state,
  (state: RootState, key: keyof RootState) => key,
  (state, key) => {
    // eslint-disable-next-line
    // @ts-ignore
    // TODO: There are a few pieces of state that do not extend RequestState thus not having an `error `property
    // We should either filter these out or figure out how to add these missing properties to the respective reducers
    const error = state[key]?.error
    if (!error) return null
    return JSON.parse(error as string)
  }
)

export const shouldRefetch = <T>(
  isUpdating: boolean,
  prev: T[] | T | null | undefined,
  impersonator: Impersonator | null,
  options?: FetchCachedThunkProps
): boolean => {
  return Boolean(
    !isUpdating && (isEmpty(prev) || !options?.getCachedResults || impersonator)
  )
}
