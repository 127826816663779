import { useFlags } from 'launchdarkly-react-client-sdk'

import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { FeatureFlags, isFeatureFlagOn } from 'components/FeatureFlag'

import { useIsOnSmallDevice } from 'hooks/mediaQueries'

import { formatPhoneNumber } from 'utils/helpers'

import {
  disabledButtonStyles,
  StyledInputContainer,
  StyledNextButton,
  StyledPhoneContainer,
  StyledInput
} from '../styles'

export interface EmailInputProps {
  shouldShowError?: boolean
  loginError?: string | null
  value?: string
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export default function EmailInput({
  shouldShowError = false,
  value,
  loginError,
  handleChange
}: EmailInputProps) {
  const { t } = useTranslation()
  const isOnSmallDevice = useIsOnSmallDevice()
  const flags = useFlags()
  const useFirebaseLoginTmx = isFeatureFlagOn(flags, FeatureFlags.useFirebaseLoginTmx)
  const isDisabled = Boolean(loginError || !value || value?.length < 3)
  const buttonText = useFirebaseLoginTmx
    ? t('pages.login.form.magicLink.cta')
    : t('pages.login.form.enabled.nextButton')

  return (
    <StyledPhoneContainer isOnSmallDevice={isOnSmallDevice} error={shouldShowError}>
      <StyledInputContainer>
        <StyledInput
          type="email"
          placeholder={t('pages.login.form.emailInputPlaceholder')}
          onChange={handleChange}
          value={formatPhoneNumber(value ?? '')}
          autoFocus
          isOnSmallDevice={isOnSmallDevice}
          data-testid="EmailInput-Enabled"
        />
      </StyledInputContainer>

      <StyledNextButton
        variant="contained"
        fullWidth
        size={isOnSmallDevice ? 'small' : 'medium'}
        disabled={isDisabled}
        type="submit"
        style={isDisabled ? disabledButtonStyles : undefined}
        data-testid="NextButton"
      >
        {buttonText}
      </StyledNextButton>
    </StyledPhoneContainer>
  )
}
