import { setupApp } from 'utils/firebase'
import { logError } from 'utils/logger'

const setupFirebase = () => {
  try {
    setupApp()
  } catch (error) {
    logError('Error initializing firebase')
  }
}

export default setupFirebase
