import {
  getAuth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  UserCredential
} from 'firebase/auth'

import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { enrichToken } from 'api'

import { LoginStateParams } from 'types'

import useAppDispatch from 'hooks/useAppDispatch'

import { useNavigation } from 'hooks/useNavigation'
import { UseRedirectOptions } from 'hooks/useRedirect'

import { firebaseTokensThunk } from 'slices/auth'
import { setSnackbar } from 'slices/snackbar'

import { RootPaths } from 'utils/helpers'
import { setDDUserSessionId } from 'utils/session'

export const useFirebase = () => {
  const [submittingFirebase, setSubmittingFirebase] = useState(false)
  const [firebaseLoginError, setFirebaseLoginError] = useState<string | null>()
  const [reCaptchaToken, setReCaptchaToken] = useState(null)
  const recaptchaVerifierRef = useRef(null)

  const location = useLocation<Partial<LoginStateParams>>()
  const verificationId = location?.state?.verificationId
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const { search } = location
  const { t } = useTranslation()
  const auth = getAuth()

  const authenticateFirebaseUser = async (
    redirectToHome: ({ returnAsComponent, ...restProps }?: UseRedirectOptions) => void,
    userProfileId?: string
  ) => {
    const auth = getAuth()
    const accessToken = await auth.currentUser?.getIdToken(true)
    const refreshToken = auth.currentUser?.refreshToken
    await dispatch(
      firebaseTokensThunk({ idToken: accessToken, refreshToken: refreshToken })
    )

    setDDUserSessionId(true)

    redirectToHome({
      returnAsComponent: false,
      search: userProfileId ? `?user_profile_id=${userProfileId}` : null
    })
  }

  const initializeReCaptchaVerifier = async () => {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
        callback: (response: never) => {
          setReCaptchaToken(response)
        },
        'expired-callback': () => {
          setReCaptchaToken(null)
          setFirebaseLoginError(t('pages.login.form.errors.expired'))
        },
        'error-callback': () => {
          setReCaptchaToken(null)
          setFirebaseLoginError(t('pages.login.form.errors.expired'))
        }
      })
    }
    await recaptchaVerifierRef.current.render().then((widgetId: RecaptchaVerifier) => {
      window.recaptchaVerifier = widgetId
    })
    // Cleanup on unmount
    return () => {
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear()
      }
    }
  }

  const sendFirebaseOTP = async (phoneNumber: string, setLoginError, loginValue) => {
    try {
      const fullPhoneNumber = `+1${phoneNumber}`
      const res = await signInWithPhoneNumber(
        auth,
        fullPhoneNumber,
        recaptchaVerifierRef.current
      )

      if (!res.verificationId) {
        setLoginError(t(`pages.login.form.errors.phoneNotFoundError`))
      } else {
        const state: LoginStateParams = {
          loginValue,
          loginType: 'phone',
          verificationId: res.verificationId
        }
        navigation.replace({
          pathname: RootPaths.verifyOTP,
          state,
          search
        })
      }
    } catch (error) {
      const errorMessage =
        error && typeof error === 'object' && 'message' in error
          ? error.message
          : 'An unknown error occurred'
      setLoginError(errorMessage)
    }
  }

  const handleFirebaseSubmit = async (otpValue: string, resetOTPValue: () => void) => {
    // Do not allow any submits if we are in the middle of submitting the form or we do not have data to submit
    if (submittingFirebase) {
      return
    }
    if (!otpValue) {
      setFirebaseLoginError(t('pages.login.form.errors.incorrectCode'))
      return
    }
    setSubmittingFirebase(true)

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otpValue)
      const initialUserCredential = await signInWithCredential(auth, credential)
      await processFirebaseCredentials(initialUserCredential)
    } catch (error) {
      setFirebaseLoginError(t('pages.login.form.errors.incorrectCode'))
    } finally {
      setSubmittingFirebase(false)
      resetOTPValue()
    }
  }

  const sendMagicLink = async (email: string): Promise<void> => {
    try {
      const {
        location: { origin, search }
      } = window
      const redirectUrl = `${origin}/login/magic-link${search}`
      const actionCodeSettings = {
        // URL you want to redirect back to.
        // URL must be in the authorized domains list in the Firebase Console.
        url: redirectUrl,
        // handleCodeInApp must be true.
        handleCodeInApp: true
      }
      await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      dispatch(
        setSnackbar({
          open: true,
          type: 'success',
          message: t('pages.login.form.magicLink.emailSent')
        })
      )

      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email)
    } catch (error) {
      const errorMessage =
        error && typeof error === 'object' && 'message' in error
          ? error.message
          : 'An unknown error occurred'
      const emailSendError = t('pages.login.form.magicLink.emailSendError')
      const errorCopy = `${emailSendError} Error: "${errorMessage}"`

      dispatch(
        setSnackbar({
          open: true,
          type: 'error',
          message: errorCopy
        })
      )
    }
  }

  const processFirebaseCredentials = async (
    initialUserCredential: UserCredential
  ): Promise<void> => {
    try {
      const originalIdToken = await initialUserCredential.user.getIdToken()
      await enrichToken({ idToken: originalIdToken })
      const idToken = await initialUserCredential.user.getIdToken(true)
      // note that instead of using a refresh token we ideally use the getIdToken call to auto refresh as needed
      const refreshToken = initialUserCredential.user.refreshToken

      if (idToken?.length && refreshToken?.length) {
        await dispatch(firebaseTokensThunk({ idToken, refreshToken }))
      } else {
        setFirebaseLoginError(t('pages.login.form.errors.unauthorized'))
      }
    } catch (error) {
      const errorMessage =
        error && typeof error === 'object' && 'message' in error
          ? error.message
          : 'An unknown error occurred'
      setFirebaseLoginError(errorMessage)
    }
  }

  const processMagicLinkReceived = async () => {
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation')
      }
      try {
        // The client SDK will parse the code from the link for you.
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn')
        const initialUserCredential = await signInWithEmailLink(
          auth,
          email,
          window.location.href
        )
        await processFirebaseCredentials(initialUserCredential)
      } catch (error) {
        const errorMessage =
          error && typeof error === 'object' && 'message' in error
            ? error.message
            : 'An unknown error occurred'
        setFirebaseLoginError(errorMessage)
      }
    }
  }

  const submitLoginForm = async (
    phoneNumber: string,
    email: string,
    setLoginError: (arg0: string) => void,
    loginType: 'phone' | 'email',
    loginValue: string
  ) => {
    if (!recaptchaVerifierRef.current) {
      setLoginError(t(`pages.login.form.errors.missingRecaptcha`))
      return
    }

    if (loginType === 'phone') {
      await sendFirebaseOTP(phoneNumber, setLoginError, loginValue)
    } else {
      await sendMagicLink(email)
    }
  }

  return {
    handleFirebaseSubmit,
    processMagicLinkReceived,
    submittingFirebase,
    firebaseLoginError,
    authenticateFirebaseUser,
    initializeReCaptchaVerifier,
    reCaptchaToken,
    recaptchaVerifierRef,
    submitLoginForm
  }
}
